<template>
  <div>
    <van-popup v-model="isPromoteRuleShow"
               :closeable="false"
               :round="true"
               :close-on-click-overlay="false"
               overlay
               class="promotePopup">
      <div class="block">
        <div class="title-popup">活动规则</div>
        <div class="content">
          <p>1、活动名称：酷联盟优酷/酷喵会员推广活动</p>
          <p>2、佣金获取方式：用户成功注册成为推广人后，在活动时间内可通过直接分享含个人专属二维码的售卡图片/专属链接的方式推广售卖指定商品，推广订单生效后推广人可以获得相应佣金，佣金可在提现有效期内提现到其绑定的支付账号。</p>
          <p>含个人专属二维码的售卖图片生成路径：“ 酷联盟个人首页”点击“分享”。</p>
          <p>计入佣金有效订单范围：推广佣金为推广人通过活动页面直接推广商品的销售佣金。但以下订单为无效订单，不享受佣金：</p>
          <p>（1）自购订单；</p>
          <p>（2）自动续费首月外续费期订单；</p>
          <p>（3）未支付成功订单；</p>
          <p>（4）退款或被撤销订单； </p>
          <p>（5）转赠订单； </p>
          <p>（6）使用代金券订单；</p>
          <p>（7）非通过二维码直接分享付款订单； </p>
          <p>（8）推广链接格式不正确而导致不能正确跟踪的订单；</p>
          <p>（9）用户从酷联盟上的推广链接访问联盟网站，在推广链接活动有效期外或本活动结束后购买的订单；</p>
          <p>（10）用于再度销售或任何商业用途的订单；</p>
          <p>（11）其他系统判定的安全漏洞或系统盘点的作弊或黑灰产行为。</p>
          <p>所有有效订单及佣金金额以酷联盟系统统计为准。 </p>
          <p>注： 您的酷联盟账号将唯一绑定用户注册时使用的优酷账号，不可更改。</p>
          <p>3、参与条件：无需缴纳任何申请费用。</p>
          <p>（1）注册酷联盟账号且绑定优酷账号，并需保证两个账号为正常状态；</p>
          <p>（2）需为年满18周岁具有完全民事行为能力的自然人 。</p>
          <p>4、营销活动说明：</p>
          <p>营销活动价格以活动期间活动页面规则为准。</p>
          <p>5、查看补贴：</p>
          <p>用户可在个人首页查看总收益。在“我的”页面查看总收益和奖励明细。</p>
          <p>6、查看售卡情况：</p>
          <p>用户可在“我的”页面-“分享订单”中实时查看售卖明细。</p>
          <p>7、结算&提现：</p>
          <p>（1）结算周期： 推广人推广有效订单产生后，佣金将进入待结算状态；
            有效订单产生后15个自然日进入可提现状态，每月的10号和15号推广人可以对可提现状态下的金额发起提现申请。
            系统审核通过后， 通常情况下，会在10个工作日内向推广人打款，打款为扣除税费等综合服务费的金额。</p>
          <p>（2）满2元可提现，单次提现最高金额10000元，结算时需按个税相关条例缴纳税费；</p>
          <p>（3）提现综合服务费：云账户按照固定比例收取综合服务费（含个税），综合服务费合计=佣金收入*7.2%；</p>
          <p>（4）提现前提：用户完成实名认证并确保三要素实名一致，提供本人名下银行卡账号后可进行结算；</p>
          <p>（5）结算打款：打款至推广人绑定的银行卡账号；</p>
          <p>（6）由灵时云（苏州）信息科技有限公司向您打款；</p>
          <p>（7）若错过当月申请结算日期，当月佣金将自动累计至下次结算。若本服务关停，最长给推广人一个月时间提走全部推广费用，否则佣金清零后果由推广人自行承担。</p>
          <p>8、违规说明：</p>
          <p>（1）推广人需严格遵守《酷联盟服务协议》以及《酷联盟成员佣金与行为规则》。如果用户出现违法、违规行为（包括但不限于恶意刷单、邀请虚假好友、虚假交易、以所获得的权益进行盈利以及违背诚实信用等），酷联盟有权取消推广人活动资格、取消违规推广人的佣金提取资格，追回佣金，严重者直接封停其账号，同时该用户的优酷账号也可能被永久封停，必要时将追究推广人的法律责任。</p>
          <p>（2）优酷会员卡或其他推广产品一经售出不予退换，若通过您推广所售出的会员产品出现退款，则不计入佣金范围。如每个结算周期退款数占其整体推广订单数超过一定比例（超过3%），或有虚假邀请好友，虚假交易行为，酷联盟有权直接判定该您存在作弊刷单行为，您的本结算周期佣金将不予结算，且酷联盟有权终止您的推广资格。如退费发生前酷联盟已经向您结算佣金的，已经结算的部分您需全额退还。</p>
          <p>9、个人信息授权</p>
          <p>为计算补贴金额、提现需求，酷联盟需收集您相关手机号、身份证信息、银行卡信息、优酷账号信息，但活动主办方需保证仅限于本次服务并在合理范围内予以保护。如您不同意我们收集您的个人信息，请谨慎参加本活动。</p>
          <p>10、如出现不可抗力或情势变更的情况(包括但不限于重大灾害事件，活动受政府机关指令需要停止举办或调整的，活动遭受严重网络攻击或因系统故障导致包括但不限于活动停办、 活动更改、实物发货和使用出现问题、监管政策变更)，活动主办方可依据相关法律法规的规定调整活动或主张免责。</p>
          <p>11、酷联盟根据业务发展需要及市场情况，有可能对本规则进行调整，并在平台上予以公告或通过其他形式通知推广人。 </p>
          <p>12、本活动由西藏福禄网络科技有限公司运营。如有疑问可随时咨询在线客服（二维码呈现）。</p>
          <p>13、本活动与设备制作商 Apple lnc.无关。</p>
        </div>
      </div>
      <div class="iconClose"
           @click="isPromoteRuleShow = false">
        <img src="../../sprite/close-icon.png"
             alt="">
      </div>
    </van-popup>
  </div>
</template>
<script>
export default {
  data() {
    return {
      isPromoteRuleShow: false
    }
  },
  mounted() {},
  methods: {}
}
</script>
<style lang="less" scoped>
.promotePopup {
  background-color: transparent;
  height: 11.18rem;

  .block {
    position: relative;
    padding-top: 0.001rem;
    width: 8.4rem;
    height: 9.4rem;
    background: #ffffff;
    border-radius: 0.21rem;
    box-sizing: border-box;

    .title-popup {
      margin: 0.4rem 0 0;
      text-align: center;
      font-size: 0.45rem;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #333333;
      line-height: 0.64rem;
    }

    .content::-webkit-scrollbar {
      display: none;
    }

    .content {
      margin: 0.27rem 0.32rem 1.24rem;
      padding: 0.27rem;
      height: 6.85rem;
      background: #f9f6f6;
      border-radius: 0.19rem;
      box-sizing: border-box;
      overflow-x: hidden;
      overflow-y: scroll;
      p{
        color:#666;
        line-height: 18px;
      }
    }
  }

  .iconClose {
    position: absolute;
    top: 9.8rem;
    left: 50%;
    width: 0.9rem;
    height: 0.9rem;
    transform: translateX(-50%);

    & > img {
      width: 100%;
      height: 100%;
    }
  }
}
</style>
